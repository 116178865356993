<script lang="ts" setup>
import { ref, onMounted, Ref, computed, watch } from "vue";
import { useHeader } from "~/composables/useHeader";
import { useRoute } from "vue-router";

const props = defineProps({
  toggleMobileDrawer: Function,
  closeMobileDrawer: Function,
});
const { count } = useCart();
const navigationbar: Ref<Element | null> = ref(null);
const navigationbarToggle: Ref<Element | null> = ref(null);
const { isMobileDrawerOpened, isNavigationBarOpened } = useHeader();
const loginModalController = useLoginModal();
const wishlistController = useWishlistModal();
const sidebarController = useSideCartModal();
const searchModalController = useSearchModal();
const { count: wishlistCount } = useWishlist();
const { isLoggedIn } = useUser();
const { formatLink } = useInternationalization();

const isCollapsed = computed(() => {
  if (isNavigationBarOpened.value === false) {
    return isMobileDrawerOpened.value;
  }

  return true;
});

onMounted(() => {
  (navigationbarToggle.value as HTMLElement)?.addEventListener("click", (e) => {
    e.preventDefault();
    props.toggleMobileDrawer();
  });
});

const route = useRoute();

// fetch the user information when params change
watch(
  () => route.path,
  (value, oldValue, onCleanup) => {
    if (value !== oldValue) {
      props.closeMobileDrawer();
      isNavigationBarOpened.value = true;
    }
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div
    :class="{
      'lg:hidden': !isCollapsed,
      'bg-maas-background-default': isMobileDrawerOpened,
    }"
    class="z-5 fixed bottom-0 w-full pt-2 px-4 py-3 flex items-center justify-center"
  >
    <nav
      :class="{
        hidden: isCollapsed,
        'w-full': !isCollapsed,
      }"
      class="flex justify-center"
    >
      <div id="navigationbar" :class="{ hidden: isCollapsed }" class="w-full max-w-md"></div>
    </nav>

    <nav ref="navigationbar" :class="{ 'w-full': isCollapsed }" class="flex items-center justify-between lg:hidden gap-4">
      <div :class="{ 'w-full': isCollapsed }" class="flex justify-center">
        <div
          :class="{
            'rounded-lg flex h-[52px] items-center justify-center border-px border-maas-button-primary-hover-background': isCollapsed,
          }"
          class="hidden overflow-hidden"
        >
          <LayoutNavigationBarButton @click="searchModalController.open">
            <svg fill="none" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M15.319 13.433a8 8 0 1 0-1.412 1.411l.043.045 4.242 4.243a1.002 1.002 0 0 0 1.632-1.09 1 1 0 0 0-.217-.325l-4.243-4.242a1.117 1.117 0 0 0-.045-.042zm-2.076-9.15a5.999 5.999 0 1 1-8.414 8.553 5.999 5.999 0 0 1 8.414-8.553z"
                fill-rule="evenodd"
                style="fill: currentColor"
              />
            </svg>
            <span class="sr-only">Suche</span>
          </LayoutNavigationBarButton>

          <span class="h-full w-px bg-maas-button-primary-hover-background"></span>

          <LayoutNavigationBarButton @click="navigateTo(formatLink('/account'))" v-if="isLoggedIn">
            <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
              <g clip-rule="evenodd" fill="#000" fill-rule="evenodd" style="fill: currentColor">
                <path d="M15 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                <path
                  d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0 1 11.065 13a8.983 8.983 0 0 1 7.092 3.458A9.001 9.001 0 1 0 2 11zm9 9a8.963 8.963 0 0 1-5.672-2.012A6.991 6.991 0 0 1 11.065 15a6.991 6.991 0 0 1 5.689 2.92A8.964 8.964 0 0 1 11 20z"
                />
              </g>
            </svg>
            <span class="sr-only">Account</span>
          </LayoutNavigationBarButton>
          <LayoutNavigationBarButton @click="loginModalController.open()" v-if="!isLoggedIn">
            <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
              <g clip-rule="evenodd" fill="#000" fill-rule="evenodd" style="fill: currentColor">
                <path d="M15 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                <path
                  d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0 1 11.065 13a8.983 8.983 0 0 1 7.092 3.458A9.001 9.001 0 1 0 2 11zm9 9a8.963 8.963 0 0 1-5.672-2.012A6.991 6.991 0 0 1 11.065 15a6.991 6.991 0 0 1 5.689 2.92A8.964 8.964 0 0 1 11 20z"
                />
              </g>
            </svg>
            <span class="sr-only">Account</span>
          </LayoutNavigationBarButton>

          <span class="h-full w-px bg-maas-button-primary-hover-background"></span>

          <LayoutNavigationBarButton @click="wishlistController.open()">
            <div class="relative">
              <svg width="23" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-current" fill-rule="evenodd" clip-rule="evenodd" d="M7.045 2.026A4.561 4.561 0 0 0 3.38 9.83l.006.006 7.638 7.565 7.638-7.565c.022-.022.045-.043.069-.062a4.56 4.56 0 1 0-6.908-5.93 1 1 0 0 1-1.601-.004 4.562 4.562 0 0 0-3.177-1.814Zm12.952 9.303-8.269 8.19a1 1 0 0 1-1.407 0L1.982 11.26a6.562 6.562 0 0 1 9.046-9.504 6.56 6.56 0 0 1 9.039 9.51 1.027 1.027 0 0 1-.07.063Z" />
              </svg>
              <span class="sr-only">Wunschliste</span>
              <span
                v-if="wishlistCount > 0"
                class="border-maas-border-light bg-maas-icon-primary text-maas-typography-navigation-light absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full border-px text-xs"
              >
                {{ wishlistCount }}
              </span>
            </div>
          </LayoutNavigationBarButton>

          <span class="h-full w-px bg-maas-button-primary-hover-background"></span>

          <LayoutNavigationBarButton @click="sidebarController.open">
            <div class="relative" >
              <svg fill="none" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-current" clip-rule="evenodd" fill-rule="evenodd" d="M3 2h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM0 3a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3zm10 7c-2.761 0-5-2.686-5-6h2c0 2.566 1.67 4 3 4s3-1.434 3-4h2c0 3.314-2.239 6-5 6z" />
              </svg>
              <span class="sr-only">Warenkorb</span>
              <span
                v-if="count > 0"
                class="border-maas-border-light bg-maas-icon-red text-maas-typography-navigation-light absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full border-px text-xs"
              >
                {{ count || "" }}
              </span>
            </div>
          </LayoutNavigationBarButton>
        </div>
      </div>

      <div ref="navigationbarToggle" class="shrink-0">
        <LayoutNavigationBarButton class="h-[52px]! w-[52px]! rounded-full border-px border-maas-border-light/10">
          <svg
            :class="{ hidden: !isMobileDrawerOpened }"
            class="scale-60"
            height="48"
            viewBox="0 -960 960 960"
            width="48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path class="fill-current" d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
          </svg>
          <span v-if="isMobileDrawerOpened" class="sr-only">Navigation schließen</span>

          <svg
            class="scale-60"
            :class="{ hidden: isMobileDrawerOpened }"
            viewBox="0 -960 960 960"
            width="48"
            height="48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path class="fill-current" d="M120-240v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
          </svg>
          <span v-if="!isMobileDrawerOpened" class="sr-only">Navigation öffnen</span>

          <span
            v-if="count > 0 && !isCollapsed"
            class="border-maas-border-light bg-maas-icon-red text-maas-typography-navigation-light absolute right-1.5 top-1.5 flex h-5 w-5 items-center justify-center rounded-full border-px text-xs"
          >
            {{ count || "" }}
          </span>
        </LayoutNavigationBarButton>
      </div>
    </nav>
  </div>
</template>

<style lang="scss">
  #navigationbar {
    button, .button {
      @apply border-px border-maas-border-light/10;
    }
  }
</style>